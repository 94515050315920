import { makeStyles } from '@material-ui/core/styles';
import { Identifier } from 'react-admin';
import { TotalWithYearGraphs } from './Statistics/TotalWithYearGraphs';
import { InvoicesTotalValues } from './Statistics/InvoicesTotalValues';

const useStyles = makeStyles({
    box: { display: 'flex', flexDirection: 'column', flexWrap: 'wrap' },
    row: { display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' },
    gridFirstRow: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
    gridSecondRow: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' },
    gridThirdRow: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' },
});

type Alert = { 
    alert: boolean;
    indicator: string;
    message: string;
    icon: string;
}

interface DashboardLayoutProps {
    contractEventsTotalQtyValue: number | undefined | string;
    contractEvents: any;
    contractEventsIds: Identifier[];
    paymentsTotalQtyValue: number | undefined;
    payments: any;
    paymentsIds: Identifier[];
    paymentsLifeTimeValue: number;
    invoicesTotalQtyValue: number | undefined;
    invoices: any;
    invoicesIds: Identifier[];
    contractsTotalQtyValue: number | undefined;
    contracts: any;
    contractsIds: Identifier[];
    contractsLifetimeValue: number;
    invoicesAlert?: Alert;
    contractEventsAlert: Alert;
}

export const DashboardLayout = ({
    contractEventsTotalQtyValue,
    contractEvents,
    contractEventsIds,
    paymentsTotalQtyValue,
    payments,
    paymentsIds,
    paymentsLifeTimeValue,
    invoicesTotalQtyValue,
    invoices,
    invoicesIds,
    contractsTotalQtyValue,
    contracts,
    contractsIds,
    contractsLifetimeValue,
    invoicesAlert,
    contractEventsAlert
}: DashboardLayoutProps) => {
    const classes = useStyles();
    let max = new Date().getFullYear();
    let min = new Date(2009, 1).getFullYear();
    let years: number[] = [];
    for (var i = min; i <= max; i++) {
        years.push(i);
    }
    return (
        <div className={classes.box} >
            <div className={classes.gridFirstRow}>
                <TotalWithYearGraphs
                    type={'value'}
                    years={years}
                    value={contractsLifetimeValue}
                    entity={contractEvents}
                    ids={contractEventsIds.filter(id => contractEvents[id]['updateValue'])}
                    subtitle={'em contratos'}
                    dateField={'date'}
                    priceField={'newValue'}
                    heightValue={250}
                    titleSize={'2rem'}
                />
                <TotalWithYearGraphs
                    type={'value'}
                    years={years}
                    value={paymentsLifeTimeValue}
                    entity={payments}
                    ids={paymentsIds}
                    subtitle={'em remessas'}
                    dateField={'settlementDate'}
                    priceField={'totalEffectiveOrder'}
                    heightValue={250}
                    titleSize={'2rem'}
                />
            </div>
            <div className={classes.gridSecondRow}>
                <TotalWithYearGraphs
                    type={'qty'}
                    years={years}
                    value={contractsTotalQtyValue}
                    entity={contracts}
                    ids={contractsIds}
                    subtitle={'contratos'}
                    dateField={'signedDate'}
                />
                <TotalWithYearGraphs
                    type={'qty'}
                    years={years}
                    value={contractEventsTotalQtyValue}
                    entity={contractEvents}
                    ids={contractEventsIds}
                    subtitle={'eventos contratutais'}
                    dateField={'date'}
                    alert={contractEventsAlert}
                />
                <TotalWithYearGraphs
                    type={'qty'}
                    years={years}
                    value={invoicesTotalQtyValue}
                    entity={invoices}
                    ids={invoicesIds}
                    subtitle={'faturas'}
                    dateField={'issueDate'}
                    alert={invoicesAlert}
                />

                <TotalWithYearGraphs
                    years={years}
                    value={paymentsTotalQtyValue}
                    entity={payments}
                    ids={paymentsIds}
                    subtitle={'remessas'}
                    dateField={'settlementDate'}
                />
            </div>
            <div className={classes.gridThirdRow}>
                <div></div><div></div>
                <InvoicesTotalValues
                    invoices={invoices}
                    currency={"EUR"} />
                <InvoicesTotalValues
                    invoices={invoices}
                    currency={"USD"} />
            </div>
        </div>
    );
}