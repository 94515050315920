import { SelectInput } from 'react-admin';

const CurrencyInput = () => {
    return (
        <SelectInput source="currency" label="Moeda" choices={[
            { id: 'BRL', name: 'Real' },
            { id: 'USD', name: 'Dólares americanos' },
            { id: 'EUR', name: 'Euro' },
        ]} />
    );
}

export default CurrencyInput;