import React, {useState} from 'react';
import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
    Link
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useStore } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => {
    let inputTextProps = {
        ...inputProps,
        value: props.textvalue
    }
    return (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        inputProps={inputTextProps}
        {...props}
        fullWidth
    />
)};

const LoginResetPasswordForm = props => {
    const store = useStore();
    const paramToken = store.getState().router.location.query.token;
    const [loading, setLoading] = useSafeSetState(false);
    const [token, setToken] = useState(paramToken);
    const [newPassword, setNewPassword] = useState(undefined);
    const translate = useTranslate();

    const classes = useStyles(props);
    const notify = useNotify();

    function handleTokenChange(evt) {
        setToken(evt.target.value); 
    }
    
    function handlePasswordChange(evt) {
        setNewPassword(evt.target.value); 
    }

    const submit = values => {
        // setLoading(true);
        let formValues = {
            token: token,
            plainPassword: newPassword
        };
        const url = `${process.env.REACT_APP_API_ENTRYPOINT}/reset_password/change`;
        const request = new Request(
            url,
            {
                method: "POST",
                body: JSON.stringify(formValues),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                setLoading(false);
                notify(translate('domain.auth.forgotPasswordChangeSuccessfull'));
                window.location.href = '/#/Login';

            }).catch(function() {
                notify(translate('domain.auth.tokenInvalid'));
                setLoading(false);
                return Promise.resolve();
                
            });
    };

    return (
        <Form
            onSubmit={submit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="token"
                                name="token"
                                component={Input}
                                label={translate('domain.auth.code')}
                                disabled={loading}
                                onChange={handleTokenChange}
                                textvalue={token}
                            />
                            <Field
                                autoFocus
                                id="plainPassword"
                                name="plainPassword"
                                component={Input}
                                label={translate('domain.auth.password')}
                                disabled={loading}
                                onChange={handlePasswordChange}
                                textvalue={newPassword}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('domain.auth.forgotPasswordButton')}
                        </Button>
                    </CardActions>
                    <CardActions>
                        <Link href="/#/Login">Fazer a login</Link>
                    </CardActions>

                </form>
            )}
        />
    );
};

export default LoginResetPasswordForm;
