import {
    DateField,
    Datagrid,
    EditButton,
    TextField,
    ShowButton
} from 'react-admin';
import DownloadButton from '../../Components/DownloadButton';
import { CurrencyFormat } from '../../Components/FormFields/CurrencyFormat';
import { PaymentsRegistered } from '../../Components/Invoice/PaymentsRegistered';
import ViewButton from '../../Components/ViewButton';

interface InvoiceDataGridProps {
    record: {
        payments: string[];
    }
    includesColumns?: string[];
}

export const InvoiceDataGrid = (props:InvoiceDataGridProps) => (
    <Datagrid>
        {props.includesColumns?.includes("supplierName") && <TextField source="supplier.name" label="Fornecedor" />}
        {props.includesColumns?.includes("contractTitle") && <TextField source="contractTitle" label="Contrato" />}
        {props.includesColumns?.includes("customerName") && <TextField source="customer.name" label="Cliente" />}
        {props.includesColumns?.includes("payments") && <PaymentsRegistered {...props} label="Pagamentos" />}
        {props.includesColumns?.includes("issueDate") && <DateField source={"issueDate"} label="Data da fatura" options={{ timeZone: 'Factory' }} />}
        {props.includesColumns?.includes("totalValue") && <CurrencyFormat source={"totalValue"} currency={"currency"} label="Valor efetivo" addLabel={true} />}
        {props.includesColumns?.includes("downloadButton") && <DownloadButton />}
        {props.includesColumns?.includes("viewButton") && <ViewButton />}
        {props.includesColumns?.includes("showButton") && <ShowButton />}
        {props.includesColumns?.includes("editButton") && <EditButton />}
    </Datagrid>
)