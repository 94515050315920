import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';
import { TextField } from 'react-admin';


const CustomerTitle = ({ record }) => {
    return <span>Cliente {record ? `"${record.name}"` : ''}</span>;
};

export const CustomerList = props => (
    <ListGuesser {...props} title={<CustomerTitle />}>
            <TextField source="name" label="Cliente" />
    </ListGuesser>
);


export const CustomerShow = props => (
    <ShowGuesser {...props} title={<CustomerTitle />}>
        <FieldGuesser source={"name"} addLabel={true} />
    </ShowGuesser>
);


export const CustomerEdit = props => (
    <EditGuesser {...props} title={<CustomerTitle />}>
        <InputGuesser source={"name"} />
    </EditGuesser>
);

export const CustomerCreate = props => (
    <CreateGuesser {...props} title={<CustomerTitle />}>
        <InputGuesser source={"name"} />
    </CreateGuesser>
);