
import {
    Edit, 
    Show, 
    Create,
    List,  
    Filter,
    ReferenceInput, 
    SelectInput,
    BulkDeleteButton,
} from 'react-admin';
import { ContractOptionText } from '../Components/Contract/ContractOptionText';
import { ContractObjectForm } from './contractObjects/ContractObjectForm';
import { ContractObjectDataGrid } from './contractObjects/ContractObjectDataGrid';
import { Fragment } from 'react';
import { ContractObjectView } from './contractObjects/ContractObjectView';

const ContractObjectTitle = ({ record }) => {
    return <span>Arquivos de contratos {record ? `- ${record.description}` : ''}</span>;
};

const transformCreate = data => ({
    ...data,
    contract: data.contract['@id'],
    formEnctype: 'multipart'
})

const ContractObjectFilters = props => (
    <Filter {...props}>
        <ReferenceInput source={"contract"} reference="contracts" label="Contrato">
            <SelectInput optionText={ContractOptionText} />
        </ReferenceInput>
    </Filter>
)

const PostBulkActionButtons = props => (
    <Fragment>
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
); 
export const ContractObjectList = props => (
    <List {...props} title={<ContractObjectTitle />} filters={<ContractObjectFilters />} bulkActionButtons={<PostBulkActionButtons />} >
        <ContractObjectDataGrid includesColumns={['title','editButton','deleteButton']} />
    </List>
);

export const ContractObjectShow = props => (
    <Show {...props} title={<ContractObjectTitle />}>
        <ContractObjectView {...props} />
    </Show>
);

export const ContractObjectEdit = props => (
    <Edit {...props} title="Editar arquivo de contrato">
        <ContractObjectForm {...props} operation="edit"/>
    </Edit>
);

export const ContractObjectCreate = props => (
    <Create {...props} title={'Criar novo arquivo de contrato'} transform={transformCreate}>
        <ContractObjectForm {...props} operation="create"/>
    </Create>
);




