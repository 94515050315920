import { List, Datagrid, TextInput, SelectInput, ReferenceInput, AutocompleteInput, TextField, EmailField, Edit, SimpleForm } from 'react-admin';
import { CreateGuesser } from '@api-platform/admin'

export const UsersList = props => {
    return (
        <>
            <List {...props} title={'Usuários'} >
                <Datagrid rowClick="edit">
                    <EmailField source="email" />
                    <TextField source="team.name" label="Time" />
                    <TextField source="fullName" />
                </Datagrid>
            </List>
        </>);

};

const UserTitle = ({ record }) => {
    return <span>Editar usuário {record ? `"${record.fullName}"` : ''}</span>;
};

const editTransform = data => ({
    ...data,
    roles: [`${data.roles}`]
});

export const UserEdit = props => (
    <Edit title={<UserTitle />} transform={editTransform} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source={"email"} />
            <ReferenceInput source="team" reference="teams" >
                <SelectInput optionText="name" valueText="@id" />
            </ReferenceInput>
            <TextInput source={"fullName"} />
            <SelectInput source={"roles"} label="Roles" choices={[
                { id: 'ROLE_ADMIN', name: 'Administrador' },
                { id: 'ROLE_USER', name: 'Usuário' }
            ]} />
        </SimpleForm>
    </Edit>

);

export const UserCreate = props => (
    <CreateGuesser title={<UserTitle />} {...props}>
        <TextInput source={"email"} />
        <ReferenceInput source="team" reference="teams" >
            <AutocompleteInput optionText="name" valueText="@id" />
        </ReferenceInput>
        <TextInput source={"fullName"} />
        <TextInput source={"password"} />
        <SelectInput source="roles" choices={[
            { id: ['ROLE_ADMIN'], name: 'Administrador' },
            { id: ['ROLE_USER'], name: 'Usuário' }
        ]} />
    </CreateGuesser>
);