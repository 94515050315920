import React, { useState, useRef, useEffect } from "react";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${left}%,-20%)`,
    };
}

const useStyles = makeStyles({
    paper: {
        position: 'absolute',
        display: 'flex',
        width: "80%",
        backgroundColor: 'white',
        padding: '4px 5px 4px 5px',
        flexDirection: 'column',
        alignItems: 'center',

    },
    button: {
        padding: '4px 5px 4px 5px',
        margin: '10px 0px 10px 0px',
    },
    page: {
        border: "1px solid black",
        width: "100%"
    }
});

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

export default function SinglePage(props) {
    const stageCanvasRef = useRef(null);
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const [modalStyle] = useState(getModalStyle);
    const [pageWidth, setPageWidth] = useState(getWidth()*0.64)

    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            if (stageCanvasRef.current) {
                timeoutId = setTimeout(() => setPageWidth(stageCanvasRef.current.offsetWidth * 0.9),150);
            }
        }
        window.addEventListener('resize', resizeListener);
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [stageCanvasRef]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const { pdf } = props;

    return (
        <div style={modalStyle} className={classes.paper} ref={stageCanvasRef}>
            <span>
                <Button className={classes.button}
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    <span>Anterior</span>
                </Button>
                <Button className={classes.button}
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    <span>Próxima</span>
                </Button>
            </span>
            <Document
                file={pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
                noData={<CircularProgress />}
            >
                <Page pageNumber={pageNumber} width={pageWidth} className={classes.page} />
            </Document>
            <p>
                Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
            </p>

        </div>
    )
}