
import { useState } from 'react';
import {
    SimpleForm,
    DateInput,
    FileInput,
    FileField,
    ReferenceInput,
    SelectInput,
    NumberInput,
    required,
    number,
    SimpleFormProps
} from 'react-admin';
import { dateFormatter } from '../../Components/FormFields/DateFormater';
import { InvoiceEventDate } from './InvoiceEventDate';



export const InvoiceForm = (props: SimpleFormProps) => {
    const [client,setClient] = useState<string|undefined>(undefined);
    
    return (
    <SimpleForm {...props}>
        <DateInput format={dateFormatter} source={"issueDate"} label="Data da emissão" />
        <ReferenceInput label="Fornecedor" source={(props.add) ? "supplier" : "supplier.@id"} reference="suppliers" validate={required()} >
            <SelectInput id="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Cliente Final" source={(props.add) ? "customer" : "customer.@id"} reference="customers" onChange={({target})=>{setClient(target.value)}}>
            <SelectInput id="id" optionText="name" />
        </ReferenceInput>
        {client && <InvoiceEventDate {...props} client={client} />}
        <NumberInput source={"totalValue"} label="Valor da fatura" validate={[required(), number()]} />
        <SelectInput source="currency" label="Moeda" choices={[
            { id: 'BRL', name: 'Real' },
            { id: 'USD', name: 'Dólares americanos' },
            { id: 'EUR', name: 'Euro' },
        ]} />
        <FileInput source="invoiceFile" label="Arquivo">
            <FileField source="src" title="title" />
        </FileInput>
    </SimpleForm>
)}