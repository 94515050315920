import {
    Datagrid,
    ReferenceManyField,
    DeleteButton,
    TextField,
    SimpleShowLayout,
    ReferenceField
} from 'react-admin';
import DownloadButton from '../../Components/DownloadButton';
import { InvoiceField } from '../invoices/InvoiceField';
import { AddNewPaymentFileObjectButton } from '../paymentObjects/AddNewPaymentFileObjectButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PaymentInfoTable } from './PaymentInfoTable/PaymentInfoTable';
import ViewButton from '../../Components/ViewButton';

const useStyles = makeStyles(
    {
        container: { display: 'flex', flex: 1, justifyContent: 'space-between' },
        containerColumn: { marginTop: "1rem", flex: 1, flexDirection: 'column', justifyContent: 'space-between' },
        tableContainer: {
            flex: 1,
            marginTop: '20px',
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            table: {
                width: '100%'
            }
        },
        centered: {
            textAlign: 'center',
            flex: 1,
            marginTop: '1rem',
        },
        newSection: {
            textAlign: 'center',
            marginTop: '4rem',
            flex: 1,
        }
    }
)

export const PaymentView = props => {
    const classes = useStyles();
    const { record } = props;
    const issueDate = new Date(record.issueDate);
    const formattedIssueDate = `${issueDate.getUTCDate()}/${issueDate.getUTCMonth() + 1}/${issueDate.getUTCFullYear()}`
    const settlementDate = new Date(record.settlementDate)
    const formattedSettlementDate = `${settlementDate.getUTCDate()}/${settlementDate.getUTCMonth() + 1}/${settlementDate.getUTCFullYear()}`
    const dateText = (record.issueDate === record.settlementDate) ?
        `Câmbio emitido e liquidado em ${formattedIssueDate}.` :
        `Câmbio emitido em ${formattedIssueDate} e liquidado em ${formattedSettlementDate}.`;
    return (
        <>
            <SimpleShowLayout  {...props}>
                <div className={classes.container}>
                    <Typography variant="h5" component="div">
                        Pagamento de fatura ao exterior
                    </Typography>
                    <Typography variant="body1" component="div">
                        Contrato nº: {record.number}
                    </Typography>
                </div>
                <div className={classes.containerColumn}>
                    <Typography variant="body2" component="div">
                        Referente a   <ReferenceField label="Fatura" source={"invoice"} reference={"invoices"}>
                            <InvoiceField {...props} />
                        </ReferenceField>
                    </Typography>
                    <Typography variant="body2" component="div">
                        {dateText}
                    </Typography>
                </div>
                <div className={classes.container} >
                    <PaymentInfoTable record={props.record} />
                </div>
                <div className={classes.container} >
                    <Typography variant="body2" component="div" className={classes.centered}>
                        Pago via <ReferenceField label="Banco" source={"bank"} reference={"banks"} >
                            <TextField source={"name"} />
                        </ReferenceField>
                    </Typography>
                </div>
                <div className={classes.container}>
                    <Typography variant="h5" component="div" className={classes.newSection}>
                        Arquivos
                    </Typography>
                </div>
                <div className={classes.container}>
                    <ReferenceManyField reference="payment-file-objects" target="payment" addLabel={true} label={"Arquivos"}>
                        <Datagrid>
                            <TextField source={"description"} addLabel={true} label="Descrição" />
                            <TextField source="fileType.description" label="Tipo" />
                            <DownloadButton />
                            <ViewButton />
                            <DeleteButton redirect={false} />
                        </Datagrid>
                    </ReferenceManyField>
                </div>
            </SimpleShowLayout>
            <SimpleShowLayout  {...props} className={classes.container}>
                <AddNewPaymentFileObjectButton record={props.record} />
            </SimpleShowLayout>

        </>
    )
}