
import { useStore } from 'react-redux'
import { Redirect, Route } from "react-router-dom";
import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { cacheDataProviderProxy } from 'react-admin';

// Data Provider
const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;
const baseurl = `${process.env.REACT_APP_API_BASEURL}`;

let getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};


const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });


const RedirectToLogin = () => {
  const introspect = useIntrospection();
  const store = useStore();
  const path = store.getState().router.location.pathname;
  if (localStorage.getItem("token") || path === "/forgot-password" || path === "/reset-password") {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};


const apiDocumentationParser = async (docspoint) => {
  try {
    const { api } = await parseHydraDocumentation(docspoint, {
      headers: getHeaders,
    });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      localStorage.removeItem("token");
      return {
        api: result.api,
        customRoutes: [
          <Route path="/" component={RedirectToLogin} />
        ],
      };
    }

    throw result;
  }
};


const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);


const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  create(resource, params) {
    if (isOperationMultipart(params)) {
      return operationWithMultipart('CREATE',resource, params);
    } else {
      return dataProvider.create(resource, params);
    }
  },
  update(resource, params) {
    if (isOperationMultipart(params)) {
      return operationWithMultipart('UPDATE',resource, params);
    } else {
      return dataProvider.update(resource, params);
    }
  },
  getUserProfile() {
    const profile = localStorage.getItem("userProfile");
    if (!profile) {
      return Promise.resolve({ data: {} });
    }
    const data = JSON.parse(profile);
    return Promise.resolve({ data });
  },
  async updateUserProfile({ data }) {
    // Convert a newly uploaded file to b64
    const avatar = await (data.avatar.rawFile instanceof File
      ? convertFileToBase64(data.avatar)
      : data.avatar);

    updateUserProfileOnServer(data);

    localStorage.setItem(
      "userProfile",
      JSON.stringify({
        ...data,
        avatar: avatar
      })
    );
    return Promise.resolve({ data });
  }
});

const isOperationMultipart = (params) => {
  return (params.data.formEnctype && params.data.formEnctype === 'multipart') ? true : false;
}

const operationWithMultipart = (operation,resource, params) => {
  let url = '';
  switch (operation) {
    default:
    case 'CREATE':
      url = `${entrypoint}/${resource}`;
      break;
    case 'UPDATE':
      url = `${baseurl}${params.id}`;
      break;
  }

  const record = params.data
  let form_data = new FormData();
  for (const name in record) {
    // eslint-disable-next-line
    if(record[name]){
      if (record[name].rawFile !== undefined) {
        form_data.append(name, record[name].rawFile)
      } else {
        form_data.append(name, record[name])
      }
    }
  }
  const headers = new Headers({
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
  });
  return baseFetchHydra(url, {
    method: "POST",
    body: form_data,
    headers,
    mode: 'cors',
  }).then(({ json }) => {
    return ({data: { ...record, id: json.id }})
  }).catch(function (error) {
    return Promise.reject(error);
  });;
}
/**
* Convert a `File` object returned by the upload input into a base 64 string.
* That's not the most optimized way to store images in production, but it's
* enough to illustrate the idea of data provider decoration.
*/
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const updateUserProfileOnServer = (data) => {
  const userId = data["@id"];
  const request = new Request(
    `${process.env.REACT_APP_API_ENTRYPOINT}${userId}`,
    {
      method: "PUT",
      mode: 'cors',
      body: JSON.stringify({ email: data.email, fullName: data.fullName }),
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept": "application/ld+json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }),
    }
  );

  return fetch(request)
    .then(response => response.json())
    .then(jsondata => {
      return jsondata
    }).catch(function (error) {
      console.log('Looks like there was a problem: \n', error);
    });
}

export default cacheDataProviderProxy(addUserProfileOverrides(dataProvider));

