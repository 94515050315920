import { useEffect, useState } from 'react';
import {
    useGetList,
    SelectInput,
    SimpleFormProps
} from 'react-admin';

import { dateFormatterBr } from '../../Components/FormFields/DateFormater';

interface InvoiceEventDateProps extends SimpleFormProps {
    client: string|undefined;
}

interface OptionsType {
    id: string | number;
    name: string;
}

export const InvoiceEventDate = (props: InvoiceEventDateProps) => {
    const [options,setOptions] = useState<OptionsType[]>([]);
    const { data: contracts, ids: contractsIds, loading: loadingConstracts } = useGetList('contracts',{ page:1, perPage:30}, undefined, {"customer": props.client});
    const { data: events, ids, loading, error } = useGetList('contract-events',{page:1, perPage:30 }, undefined,{"contract": contractsIds});
    const { data: eventTypes, loading: loadingEventTypes } = useGetList('contract-event-types');

    useEffect(() => {
        var newOptions = [] as OptionsType[];
        ids.forEach(id => {
            if(typeof contracts[events[id].contract]?.title !== 'undefined') {
                newOptions.push({ "id": id, name: `${eventTypes[events[id].type].name} (${contracts[events[id].contract].title}) em ${dateFormatterBr(events[id].date)}` })
            }
    
        });
        setOptions(newOptions);
    } , [contracts, events, eventTypes, ids]);
  
    if (loading || loadingEventTypes || loadingConstracts) { return <span>Carregando...</span>; }
    if (error) { return <p>ERROR</p>; }
    return (
        <SelectInput source={"contractEvent"} choices={options} label="Evento de contrato" />
    )
}