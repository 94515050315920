import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';


export const themeProvider = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: '#406c99',
      main: '#01426a',
      dark: '#001b40',
      contrastText: '#fff',
    },
    secondary: {
      main: '#01426a'
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: '0.75rem',
      }
    },
    typography: {
      fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
      htmlFontSize: '93.75%',
    },
    MuiListItemText: {
      primary: {
        fontSize: '0.6rem',

      }
    },
  },
  MuiAvatar: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        variant: 'square',
      },
    },
  }
});


export default themeProvider;