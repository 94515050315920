import {
    Datagrid,
    ShowButton,
    EditButton,
    TextField,
    DateField,
    ReferenceField,
    DatagridProps
} from 'react-admin';
import { CurrencyFormat } from '../../Components/FormFields/CurrencyFormat';
import { InvoiceField } from '../invoices/InvoiceField';

export const PaymentDatagrid = (props:DatagridProps) => (
    <Datagrid>
        <ReferenceField label="Fatura" source={"invoice"} reference={"invoices"}>
            <InvoiceField {...props} />
        </ReferenceField>
        <ReferenceField label="Banco" source={"bank"} reference={"banks"} >
            <TextField source={"name"} />
        </ReferenceField>
        <DateField source={"settlementDate"} label="Data Efetivada" addLabel={true} options={{ timeZone: 'Factory' }}/>
        <CurrencyFormat source={"totalCurrencyInForeignCurrency"} currency={"currency"} label="Valor Fatura" addLabel={true} />
        <CurrencyFormat source={"totalEffectiveOrder"} label="Valor Pago" addLabel={true} />
        <ShowButton />
        <EditButton />
    </Datagrid>
)