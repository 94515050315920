export const ContractTerm = ({ record }) => {
    if (record.earliestStartDateFromEvents == null) {
        return (<span>N/D</span>)
    }
    var start = new Date(record.earliestStartDateFromEvents);
    var end = new Date(record.latestEndDateFromEvents);
    return (
        <span>{`${start.getUTCDate()}/${start.getUTCMonth() + 1}/${start.getUTCFullYear()} - 
        ${end.getUTCDate()}/${end.getUTCMonth() + 1}/${end.getUTCFullYear()}`}</span>
        )
}