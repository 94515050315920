import { Layout } from 'react-admin';
import LayoutHeaderBar from './LayoutHeaderBar';
import { ProfileProvider } from '../providers/ProfileProvider'

const AppLayout = (props) => {
    return (
    <ProfileProvider>
        <Layout 
            {...props} 
            appBar={LayoutHeaderBar} 
        />
    </ProfileProvider>
    )
};

export default AppLayout;