import {
    FileInput,
    TextInput,
    SimpleForm,
    FileField,
    ReferenceInput,
    required,
    SelectInput,
    useRecordContext
} from 'react-admin';
import { useLocation } from "react-router-dom";
import { ContractOptionText } from '../../Components/Contract/ContractOptionText';

export const ContractObjectForm = (props) => {
    const record = useRecordContext();
    const location = useLocation();
    const redirect = getRedirect(location);
    const initialValue = location?.state?.contract ? location.state.contract : null;

    return (
        <SimpleForm redirect={redirect ? redirect : 'list'} save={props.save} record={record}>
            <ReferenceInput
                source={"contract.@id"}
                reference="contracts"
                validate={[required()]}
                disabled={props.operation === "edit" || initialValue ? true : false}>
                <SelectInput optionValue={'@id'} optionText={ContractOptionText} initialValue={initialValue} />
            </ReferenceInput>
            <TextInput source="description" title="Descrição" validate={[required()]} />
            <ReferenceInput source={"fileType"} reference="file-types" validate={[required()]}
                filterToQuery={searchText => ({ "entity.name": "Contratos" })}>
                <SelectInput optionText="description" />
            </ReferenceInput>
            <FileInput source="file">
                <FileField source="src" title="title" maxSize={5000000} />
            </FileInput>
        </SimpleForm>
    )
}

const getRedirect = (location) => {
    const contract = location.state?.contract ? location.state?.contract : null;
    const redirect = contract ? `/contracts/${encodeURIComponent(contract)}/show/files` : "list";
    return redirect;
}