import { FieldGuesser, ListGuesser } from '@api-platform/admin';
import {
    Create, SimpleForm, TopToolbar,
    
    EditButton, ReferenceInput, SelectInput, Show, SimpleShowLayout,
    DateField, TextField, Edit, TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DownloadButton from '../Components/DownloadButton';


const useStyles = makeStyles({
    box: {
        display: 'flex',
        justifyContent: 'space-between',
    }
});


const FileTypeTitle = ({ record }) => {
    return <span>Fatura</span>; //{record ? `"${record.supplier}"` : ''}</span>;
};

const FileTypeShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const FileTypeList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"description"} />
        <TextField source={"entity.name"} />
    </ListGuesser>
);

export const FileTypeShow = props => {
    const classes = useStyles();
    return (
        <Show {...props} title={<FileTypeTitle />} actions={<FileTypeShowActions />}>
            <SimpleShowLayout className={classes.box}>
                <TextField source="supplier.name" label="Fornecedor" />
                <TextField source="customer.name" label="Cliente" />
                <DateField source={"issueDate"} label="Data da fatura" />
                <DownloadButton />
            </SimpleShowLayout>
        </Show>
    )
};


export const FileTypeEdit = props => (
    <Edit {...props} title={<FileTypeTitle />}>
         <FileTypeForm  />
    </Edit>
);

export const FileTypeCreate = props => (
    <Create {...props}>
        <FileTypeForm  />
    </Create>
);

const FileTypeForm = props => (
    <SimpleForm {...props}>
        <TextInput label="Descrição" source={"description"} />
        <ReferenceInput source={"entity"} label="Entidade" reference="entities" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>
)