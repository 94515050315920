import { Route } from "react-router-dom";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
// Components
import Dashboard from './Components/Dashboard'
import Layout from './Components/AppLayout';
import LayoutMenu from './Components/LayoutMenu';
import CustomLoginPage from './Components/CustomLoginPage';
import LoginForgotPassword from './Components/LoginForgotPassword';
import LoginResetPassword from './Components/LoginResetPassword';
// Resources
import { UsersList, UserEdit, UserCreate } from './resources/users'
import { ProfileEdit } from './resources/profile';
import { MediaObjectList, MediaObjectShow, MediaObjectEdit, MediaObjectCreate } from './resources/mediaObjects';
import { ContractObjectList, ContractObjectShow, ContractObjectEdit, ContractObjectCreate } from './resources/contractObjects';
import { ContractEventList, ContractEventShow, ContractEventEdit, ContractEventCreate } from './resources/contractEvents';
import { PaymentObjectList, PaymentObjectShow, PaymentObjectEdit, PaymentObjectCreate } from './resources/paymentObjects';
import { InvoiceList, InvoiceShow, InvoiceEdit, InvoiceCreate } from './resources/invoices';
import { SupplierList, SupplierShow, SupplierEdit, SupplierCreate } from './resources/suppliers';
import { CustomerList, CustomerShow, CustomerEdit, CustomerCreate } from './resources/customers';
import { ContractList, ContractShow, ContractEdit, ContractCreate } from './resources/contracts';
import { PaymentList, PaymentShow, PaymentEdit, PaymentCreate } from './resources/payments';
import { EntityCreate, EntityEdit, EntityList, EntityShow } from "./resources/entities";
import { FileTypeCreate, FileTypeEdit, FileTypeList } from "./resources/fileTypes";
import { BankCreate, BankEdit, BankList, BankShow } from "./resources/banks";
// Providers
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import themeProvider from './providers/themeProvider';
import i18nProvider from './providers/i18nProvider';


const title = 'ETTBrasil Software Reseller'

const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;

const App = () => (
  <HydraAdmin dashboard={Dashboard}
    layout={Layout}
    loginPage={CustomLoginPage}
    authProvider={authProvider}
    menu={LayoutMenu}
    theme={themeProvider}
    title={title}
    dataProvider={dataProvider}
    entrypoint={entrypoint}
    i18nProvider={i18nProvider}
    disableTelemetry
    customRoutes={[
      <Route key="my-profile" path="/my-profile" component={ProfileEdit} />,
      <Route path="/forgot-password" render={(props) => (<LoginForgotPassword {...props} theme={themeProvider} />)} noLayout />,
      <Route path="/reset-password" render={(props) => (<LoginResetPassword {...props} theme={themeProvider} />)} noLayout />,
    ]}
  >
    {permissions => [

      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"users"} list={UsersList} edit={UserEdit} create={UserCreate} options={{ label: 'Usuários' }} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"suppliers"} list={SupplierList} show={SupplierShow} edit={SupplierEdit} create={SupplierCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"customers"} list={CustomerList} show={CustomerShow} edit={CustomerEdit} create={CustomerCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"invoices"} list={InvoiceList} show={InvoiceShow} edit={InvoiceEdit} create={InvoiceCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"media-objects"} list={MediaObjectList} show={MediaObjectShow} edit={MediaObjectEdit} create={MediaObjectCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"contract-file-objects"} list={ContractObjectList} show={ContractObjectShow} edit={ContractObjectEdit} create={ContractObjectCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"payment-file-objects"} list={PaymentObjectList} show={PaymentObjectShow} edit={PaymentObjectEdit} create={PaymentObjectCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"contracts"} list={ContractList} show={ContractShow} edit={ContractEdit} create={ContractCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"contract-events"} list={ContractEventList} show={ContractEventShow} edit={ContractEventEdit} create={ContractEventCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"contract-event-types"} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"payments"} list={PaymentList} show={PaymentShow} edit={PaymentEdit} create={PaymentCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"entities"} list={EntityList} show={EntityShow} edit={EntityEdit} create={EntityCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"banks"} list={BankList} show={BankShow} edit={BankEdit} create={BankCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"file-types"} list={FileTypeList} edit={FileTypeEdit} create={FileTypeCreate} /> : null,
    ]}
  </HydraAdmin>
);
export default App;