import {
    ReferenceManyField,
    SimpleShowLayout,
} from 'react-admin';
import { PaymentDatagrid } from '../payments/PaymentDatagrid';
import { AddNewPaymentButton } from '../payments/AddNewPaymentButton';
import { InvoiceSummary } from './InvoiceSummary';

export function InvoiceView(props): JSX.Element {
    return (
        <SimpleShowLayout {...props}>
            <h1>Resumo </h1>
            <InvoiceSummary {...props} />
            <h2>Pagamentos </h2>
            <ReferenceManyField reference="payments" target="invoice" addLabel={false} sort={{ field: 'date', order: 'ASC' }}>
                <PaymentDatagrid />
            </ReferenceManyField>
            <AddNewPaymentButton record={props.record} />
        </SimpleShowLayout>
    );
}