
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CurrencyFormat } from '../../Components/FormFields/CurrencyFormat';
import DownloadButton from '../../Components/DownloadButton';
import ViewButton from '../../Components/ViewButton';
import { DateField } from 'react-admin';
import { RegisteredPayments } from './RegisteredPayments';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
});

export const InvoiceSummary = (props) => {
  const classes = useStyles();
  const { record } = props;


  return (
    <Card className={classes.root} variant="outlined">
      <CardContent >
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Emitido por: {record.supplier.name}
        </Typography>
        <Typography variant="h5" component="h2">
          {record.customer.name} <br />
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Emitido em: <DateField source={"issueDate"} label="Data da Fatura" options={{ timeZone: 'Factory' }} />
          <br />Valor total da fatura: <CurrencyFormat source={"totalValue"} currency={"currency"} {...props} />
          <br />Referente ao contrato: {record.contractTitle}
          <RegisteredPayments {...props} />
        </Typography>
      </CardContent>
      <CardActions>
        {record?.hasAttachedFile && <DownloadButton {...props} />}
        {record?.hasAttachedFile && <ViewButton {...props} />}
      </CardActions>
    </Card>
  );
}


