import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import "@carbon/charts/styles.css";
import { useMemo } from 'react';

const useStyles = makeStyles({
    root: {
        margin: '5px',
        minWidth: 'max-content',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

interface InvoicesTotalValuesProps {
    invoices: {
        currancy?: any;
        totalValue?: number;
    };
    currency: "EUR" | "USD";
}

export const InvoicesTotalValues = ({ invoices, currency }: InvoicesTotalValuesProps) => {
    const classes = useStyles();


    const totalValue = useMemo(() => {
        return Object.entries(invoices).map((obj) => obj[1]).filter((obj) => (obj.currency === currency)).map((obj) => (obj.totalValue))
            .reduce((a, b) => a + b, 0)
    }, [invoices, currency])

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent >
                <Typography variant="h5" component="h2">
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(totalValue / 100)}
                </Typography>
                {(currency === 'USD') ? 'em dólares' : 'em euros'}
            </CardContent>
        </Card >
    );
};