import { FieldGuesser } from '@api-platform/admin';
import {
    Show,
    Create,
    Edit,
    List,
    Button,
    DateField,
    TextField,
    ReferenceField
} from 'react-admin';
import DownloadButton from '../Components/DownloadButton';
import { PaymentObjectForm } from './paymentObjects/PaymentObjectForm';
import { PaymentObjectDataGrid } from './paymentObjects/PaymentObjectDataGrid';

const PaymentObjectTitle = ({ record }) => {
    return <span>Documentos {record ? `"${record.name}"` : ''}</span>;
};

export const PaymentObjectList = props => (
    <List {...props}>
        <PaymentObjectDataGrid includesColumns={["editButton", "deleteButton"]} />
    </List>
);

const transformCreate = data => ({
    ...data,
    payment: data.payment['@id'],
    formEnctype: 'multipart'
})

export const PaymentObjectShow = props => (
    <Show {...props} title={<PaymentObjectTitle />}>
        <FieldGuesser source={"filePath"} addLabel={true} />
        <ReferenceField source={"payment.customer"} reference="customers" label="Cliente" >
            <TextField source={"name"} />
        </ReferenceField>
        <DateField source={"payment.signedDate"} label="Data da assinatura do contrato" />
        <ReferenceField source={"payment.[@id]"} reference="payments" addLabel={false} >
            <Button label="Ir para o contrato" />
        </ReferenceField>
        <DownloadButton />
    </Show>
);


export const PaymentObjectEdit = props => (
    <Edit {...props}  title="Editar arquivo de pagamento">
        <PaymentObjectForm {...props} operation="edit" />
    </Edit>
);

export const PaymentObjectCreate = props => (
    <Create {...props} transform={transformCreate}>
        <PaymentObjectForm {...props} operation="create" />
    </Create>
);