import {
    SimpleForm,
    NumberInput,
    DateInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import CurrencyInput from '../../Components/FormFields/CurrencyInput';
import { InvoiceField } from '../invoices/InvoiceField';
import { useLocation } from "react-router-dom";
import { useState } from 'react';

export const PaymentForm = (props) => {
    const location = useLocation();
    const [client, setClient] = useState<string | undefined>(undefined);
    const [edit, setEdit] = useState<boolean>(false);
    const [contract, setContract] = useState<string | undefined>(undefined);
    const redirect = getRedirect(location);
    const initialValue = location?.state?.invoice ? location.state.invoice : null;

    return (
        <SimpleForm redirect={redirect ? redirect : 'list'} {...props}>
            <NumberInput source={"number"} />
            <DateInput source={"issueDate"} />
            <DateInput source={"settlementDate"} />
            <NumberInput source={"exchangeRate"} />
            <NumberInput source={"totalCurrencyInForeignCurrency"} />
            <CurrencyInput />
            <NumberInput source={"iofRate"} />
            <NumberInput source={"bankFees"} />
            <NumberInput source={"totalEffectiveOrder"} />

            <button
                style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textAlign: 'left'
                }}
                onClick={(e) => { e.preventDefault(); setEdit(true) }}>Localizar fatura
            </button>
            {
                edit && <ReferenceInput label="Cliente Final" source={(props.add) ? "customer" : "customer.@id"} reference="customers" onChange={({ target }) => { setClient(target.value) }}>
                    <SelectInput id="id" optionText="name" />
                </ReferenceInput>
            }
            {
                client &&
                <ReferenceInput label="Contrato" source={"contracts"} filter={{ customer: client }} reference="contracts" onChange={({ target }) => { setContract(target.value) }}>
                    <SelectInput id="id" optionText="title" />
                </ReferenceInput>
            }
            <ReferenceInput source={"invoice"} reference={"invoices"} filter={{ 'contractEvent.contract': contract }}
                sort={{ field: 'issueDate', order: 'ASC' }}
                disabled={initialValue || contract === undefined ? true : false}>
                <SelectInput optionText={<InvoiceField record={props.record} />} initialValue={initialValue} />
            </ReferenceInput>


            <ReferenceInput source={"bank"} reference={"banks"} >
                <SelectInput optionText={"name"} />
            </ReferenceInput>
        </SimpleForm >
    )
}

const getRedirect = (location) => {
    const invoice = location.state?.invoice ? location.state?.invoice : null;
    const redirect = invoice ? `/invoices/${encodeURIComponent(invoice)}/show` : "list";
    return redirect;
}