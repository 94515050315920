import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, fetchStart, fetchEnd, Button, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

const entrypoint = `${process.env.REACT_APP_API_BASEURL}`;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    Accept: `application/json`
} : {};

const useStyles = makeStyles({
    button: {
        // fontWeight: 'bold',
        padding: '4px 5px 4px 5px',
        margin: '10px 0px 10px 0px',
        //border: '1px solid darkblue',
    },
});

const DownloadButton = () => {
    const record = useRecordContext();
    const classes = useStyles();
    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator 
        fetch(`${entrypoint}${record.id}/download`, { method: 'GET', mode: 'cors', headers: getHeaders() })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data.fileAsBase64;
            })
            .then((invoice) => {
                var link = document.createElement("a");
                link.setAttribute('download', `${record.filePath}`);
                link.href = invoice;
                document.body.appendChild(link);
                link.click();
                link.remove();
                notify('File downloaded successfully.');
            })
            .catch((e) => {
                notify('Error: File not downloaded', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };
    
    if(record.hasAttachedFile === false){
        return <></>;
    } else {
        return <Button label="Download" className={classes.button} onClick={handleClick} disabled={loading}><GetAppIcon /></Button>;
    }
    
};

export default DownloadButton;