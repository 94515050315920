

import {
    SimpleShowLayout,
    Button, 
    DateField, 
    TextField, 
    ReferenceField
} from 'react-admin';
import DownloadButton from '../../Components/DownloadButton';
import ViewButton from '../../Components/ViewButton';

export const ContractObjectView = props => (
    <SimpleShowLayout>
        <TextField source={"filePath"} addLabel={true} />
        <TextField source={"description"} label="Descrição" />
        <DateField source={"contract.signedDate"} label="Data da assinatura do contrato" />
        <TextField source={"contract.customer.name"} label="Cliente" />
        <DownloadButton />
        <ViewButton />
        <ReferenceField source={"contract.[@id]"} reference="contracts" addLabel={false} >
            <Button label="Ir para o contrato" />
        </ReferenceField>
    </SimpleShowLayout>
)