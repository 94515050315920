import {
    FunctionField
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    display: {
        display: 'flex',
        alignItems: 'center',
    },
    invoices: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#fff',
    },
    field: {
        display: 'flex',
    },
    green: {
        backgroundColor: 'green',
    },
    red: {
        backgroundColor: 'red',
    }
}));

interface TermsProps {
    record: {
        newStartDate: string;
        newEndDate: string;
        updateDate: boolean;
    },
    label: string;
}

export const Terms = ({ record, label }: TermsProps) => {
    const classes = useStyles();
    return (
        <FunctionField className={classes.field} label={label} render={() => {
            const newStartDateFormatted = new Date(record?.newStartDate).toLocaleDateString('pt-BR', { timeZone: 'Factory' });
            const newEndDateFormatted = new Date(record?.newEndDate).toLocaleDateString('pt-BR', { timeZone: 'Factory'});
            return record?.updateDate ?
            <span className={classes.display}>
                   <DoneIcon /> ({newStartDateFormatted}-{newEndDateFormatted})
            </span> :
            <span>
                <ClearIcon />
            </span>
        }
        } />
    )
}