import {
    FunctionField
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    invoices: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#fff',
    },
    field: {
        display: 'flex',
    },
    green: {
        backgroundColor: 'green',
    },
    red: {
        backgroundColor: 'red',
    }
}));

interface InvoicesRegisteredProps {
    record: {
        invoices: string[];
        updateValue: boolean;
    },
    label: string;
}

export const InvoicesRegistered = ({ record, label }: InvoicesRegisteredProps) => {
    const classes = useStyles();
    return (
        <FunctionField className={classes.field} label={label} render={() =>
            <Chip label={record?.invoices?.length} size="small" className={`${classes.invoices} ${(record?.invoices?.length > 0 || !record?.updateValue) ? classes.green : classes.red}`} />
        } />
    )
}