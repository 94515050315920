import { useGetList } from 'react-admin';
import {
    Datagrid,
    DateField,
    TextField
} from 'react-admin';
import { dateFormatterBr } from '../../Components/FormFields/DateFormater';
import ViewButton from '../../Components/ViewButton';
import { useEffect, useState } from 'react';

export const ContractInvoicesWithDatagrid = ({ record }) => {
    const currentSort = { field: 'issueDate', order: 'asc'};
    const [loaded, setLoaded] = useState(false);
    const [rows, setRows] = useState({});
    const contractId = record.id;
    const { data, ids, loading: loadingInvoices, total } = useGetList(
        'invoices',
        undefined,
        currentSort,
        { 'contractEvent.contract': contractId }
    );
    const { data: eventData, loading: loadingEventData } = useGetList(
        'contract-events',
        undefined,
        undefined,
        { 'contract': contractId }
    );
    const { data: eventType, loading: loadingEventType } = useGetList(
        'contract-event-types'
    );

    useEffect(() => {
        const newRows = ids.map(id => {
            let currency = data[id].currency;
            let eventTypeId = eventData[data[id].contractEvent]?.type;
            let eventDate = eventData[data[id].contractEvent]?.date;
            return ({
                "id": id,
                "supplier": data[id].supplier.name,
                "issueDate": data[id].issueDate,
                "totalValue": new Intl.NumberFormat((currency === 'USD') ? 'en-US' : (currency === 'EUR') ? 'en-UK' : 'pt-BR', { style: 'currency', currency: currency }).format(data[id].totalValue / 100),
                "relatedEvent": (eventTypeId) ? `${eventType[eventTypeId]?.name} em ${dateFormatterBr(eventDate)}` : 'Não disponível',
            })
        }).reduce((obj, item)=> Object.assign(obj, {[item.id]: item}), {})
        setRows(newRows)
    }, [eventType, eventData, ids, data])

    useEffect(() => {
        if (loadingInvoices || loadingEventData || loadingEventType){ 
            setLoaded(false);
        } else {
            setLoaded(true);
        }
    },[loadingInvoices,loadingEventData,loadingEventType])

    return (
        <Datagrid
            basePath=""
            currentSort={currentSort}
            data={rows}
            ids={ids}
            loaded={loaded}
            total={total}
        >
            <TextField source="supplier" label="Fornecedor" />
            <DateField source="issueDate" label="Data" options={{ timeZone: 'Factory' }} />
            <TextField source="totalValue" label="Valor" />
            <TextField source="relatedEvent" label="Evento Relacionado" />
            <ViewButton />
        </Datagrid>

    );
}