
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CurrencyFormat } from '../../Components/FormFields/CurrencyFormat';
import DownloadButton from '../../Components/DownloadButton';
import { DateField } from 'react-admin';
import { ContractStatus } from '../../Components/Contract/ContractStatus';
import { ContractTerm } from '../../Components/Contract/ContractTerm';
import { GrMoney} from 'react-icons/gr';
import { GiSandsOfTime } from 'react-icons/gi';
 
const useStyles = makeStyles({
  display: {
    display: 'flex',
    alignItems: 'center',
},
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
});

export const ContractOriginalInfoView = (props) => {
  const classes = useStyles();
  const { record } = props;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent >
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {record.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {record.customer.name} <br /><ContractStatus record={record} />
        </Typography><br/>
        <Typography variant="h5" component="h2">
          <b className={classes.display}><GrMoney /><CurrencyFormat source={"totalContractValue"} currency={"currency"} addLabel={true} label="Valor original do contrato:" {...props} /> | <GiSandsOfTime /> <ContractTerm {...props} /> </b>
        </Typography>
          
        <Typography className={classes.pos} color="textSecondary">
          Período original de vigência: (
          <DateField source={"startDate"} label="Data original de início da Vigência" options={{ timeZone: 'UTC' }} /> -
          <DateField source={"endDate"} label="Data original de término da Vigência" options={{ timeZone: 'UTC' }} />
          )
          <br />
          Data de assinatura do contrato:
          <DateField source={"signedDate"} label="Data original de término da Vigência" options={{ timeZone: 'Factory' }} />
          <br />


        </Typography>
      </CardContent>
      <CardActions>
        {record?.hasAttachedFile && <DownloadButton {...props} />}
      </CardActions>
    </Card>
  );
}
