import {
    ReferenceField,
    Datagrid,
    ShowButton,
    EditButton,
    DateField,
    TextField
} from 'react-admin';
import { InvoicesRegistered } from '../../Components/ContractEvents/InvoicesRegistered';
import { PriceValues } from '../../Components/ContractEvents/PriceValues';
import { Terms } from '../../Components/ContractEvents/Terms';

export const ContractEventDataGrid = props => {
    return (
    <Datagrid >
        <DateField source="date" label="Data" options={{ timeZone: 'Factory' }}/>
        {props.includesColumns?.includes("contract") &&
            <ReferenceField source="contract" reference={"contracts"} link={false} label="Contrato" sortable={false}>
                <TextField source="title" />
            </ReferenceField>}
        <ReferenceField source="type" reference={"contract-event-types"} link={false} label="Tipo de evento" sortable={false}>
            <TextField source="name" />
        </ReferenceField>
        {props.includesColumns?.includes("terms") && <Terms {...props} label="Nova vigência?" />}
        {props.includesColumns?.includes("price") && <PriceValues label="Novo valor?" currency={props.currency} record={props.record} />}
        {props.includesColumns?.includes("invoices") && <InvoicesRegistered {...props} label="Faturas" />}
        {props.includesColumns?.includes("showButton") && <ShowButton />}
        {props.includesColumns?.includes("editButton") && <EditButton />}
    </Datagrid>
)}
