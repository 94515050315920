import * as React from 'react';
import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForgotPasswordForm = props => {
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const classes = useStyles(props);
    const notify = useNotify();

    const validate = (values) => {
        const errors = { email: undefined };

        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = values => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_ENTRYPOINT}/reset_password/request`;
        
        const request = new Request(
            url,
            {
                method: "POST",
                body: JSON.stringify(values),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                setLoading(false);
                notify(translate('domain.auth.forgotPasswordMailSentMessage'));
            });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus={false}
                                id="email"
                                name="email"
                                component={Input}
                                label={translate('domain.auth.email')}
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('domain.auth.forgotPasswordButton')}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

export default LoginForgotPasswordForm;
