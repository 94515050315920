export const dateFormatter = v => {
    const issueDate = new Date(v)
    const yy = issueDate.getUTCFullYear().toString();
    const mm = `${(issueDate.getUTCMonth() + 1) < 10? "0":""}${(issueDate.getUTCMonth() + 1)}`;
    const dd = `${issueDate.getUTCDate() < 10? "0":""}${issueDate.getUTCDate()}`;
    return `${yy}-${mm}-${dd}`;
};

export const dateFormatterBr = v => {
    const issueDate = new Date(v)
    const yy = issueDate.getUTCFullYear().toString();
    const mm = `${(issueDate.getUTCMonth() + 1) < 10? "0":""}${(issueDate.getUTCMonth() + 1)}`;
    const dd = `${issueDate.getUTCDate() < 10? "0":""}${issueDate.getUTCDate()}`;
    return `${dd}/${mm}/${yy}`;
};