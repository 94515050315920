import { ListGuesser, FieldGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';
import { TextField, Toolbar, SaveButton, Create, SimpleForm } from 'react-admin';

const SupplierCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
        <SaveButton
            label="post.action.save_and_notify"
            transform={data => ({ ...data, multipart: true })}
            submitOnEnter={false}
        />
    </Toolbar>
);

const SupplierTitle = ({ record }) => {
    return <span>Fornecedor {record ? `"${record.name}"` : ''}</span>;
};

export const SupplierList = props => (
    <ListGuesser {...props} title={<SupplierTitle />}>
        <TextField source="name" label="Fornecedor" />
    </ListGuesser>
);


export const SupplierShow = props => (
    <ShowGuesser {...props} title={<SupplierTitle />}>
        <FieldGuesser source={"name"} addLabel={true} />
    </ShowGuesser>
);


export const SupplierEdit = props => (
    <EditGuesser {...props} title={<SupplierTitle />}>
        <InputGuesser source={"name"} />
    </EditGuesser>
);

export const SupplierCreate = props => (
    <Create {...props} title={<SupplierTitle />}>
        <SimpleForm toolbar={<SupplierCreateToolbar />}>
            <InputGuesser source={"name"} />
        </SimpleForm>
    </Create>
);