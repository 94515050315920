import {
    DateField, 
    Datagrid,  
    EditButton,
    TextField, 
    ReferenceField,
    DeleteButton
} from 'react-admin';
import DownloadButton from '../../Components/DownloadButton';
import ViewButton from '../../Components/ViewButton';


export const ContractObjectDataGrid = props => (
    <Datagrid>
        <TextField source={"description"} addLabel={true} label="Descrição" />
        <ReferenceField source={"fileType"} reference="file-types" label="Tipo" sortBy="fileType.description" >
            <TextField source={"description"} />
        </ReferenceField>
        {props.includesColumns?.includes("title") && <TextField source={"contract.title"}  label="Contrato"/>}
        {props.includesColumns?.includes("customer.name") && <TextField source={"contract.customer.name"}  label="Cliente"/>}
        {props.includesColumns?.includes("signedDate") && <DateField source={"contract.signedDate"} label="Data da assinatura do contrato" options={{ timeZone: 'Factory' }} />}
        <DownloadButton />
        <ViewButton />
        {props.includesColumns?.includes("editButton") && <EditButton />}
        {props.includesColumns?.includes("deleteButton") && <DeleteButton />}
    </Datagrid>
)