import {
    SimpleForm,
    FormDataConsumer,
    BooleanInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    required,
    number,
} from 'react-admin';
import { ContractOptionText } from '../../Components/Contract/ContractOptionText';
import { dateFormatter } from '../../Components/FormFields/DateFormater';
import { useLocation } from "react-router-dom";

export const ContractEventForm = (props) => {
    const location = useLocation();
    const redirect = getRedirect(location);
    const initialValue = location?.state?.contract ? location.state.contract : null;

    return (
        <SimpleForm {...props} redirect={redirect ? redirect : 'list'}>
            <ReferenceInput source={"contract"} reference={"contracts"} label={"Contrato"} validate={required()} disabled={initialValue ? true : false}>
                <SelectInput optionText={ContractOptionText} initialValue={initialValue} />
            </ReferenceInput>
            <ReferenceInput source={"type"} reference={"contract-event-types"} label={"Tipo de evento"} validate={required()} >
                <SelectInput optionText={"name"} />
            </ReferenceInput>
            <DateInput source={"date"} label="Data do evento" format={dateFormatter} initialValue={new Date().toISOString().slice(0, 10)} validate={required()} />
            <BooleanInput source={"updateDate"} initialValue={false} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.updateDate) {
                        return (<>
                            <DateInput source={"newStartDate"} format={dateFormatter} label="Início da vigência" initialValue={new Date().toISOString().slice(0, 10)} />
                            <DateInput source={"newEndDate"} format={dateFormatter} label="Término da vigência" initialValue={new Date().toISOString().slice(0, 10)} />
                        </>)
                    }
                }}
            </FormDataConsumer>
            <BooleanInput source={"updateValue"} initialValue={false} />
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.updateValue &&
                    <NumberInput source={"newValue"} label="Valor do contrato" step={1} validate={[required(), number()]} />
                }
            </FormDataConsumer>
        </SimpleForm>
    )
}

const getRedirect = (location) => {
    const contract = location.state?.contract ? location.state?.contract : null;
    const redirect = contract ? `/contracts/${encodeURIComponent(contract)}/show/files` : "list";
    return redirect;
}