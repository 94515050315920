import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';
import { FileInput, FileField } from 'react-admin';


const MediaObjectTitle = ({ record }) => {
    return <span>Meio de pagamento {record ? `"${record.name}"` : ''}</span>;
};

export const MediaObjectList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"contentUrl"} />
    </ListGuesser>
);


export const MediaObjectShow = props => (
    <ShowGuesser {...props} title={<MediaObjectTitle />}>
        <FieldGuesser source={"contentUrl"} addLabel={true} />
    </ShowGuesser>
);


export const MediaObjectEdit = props => (
    <EditGuesser {...props} title={<MediaObjectTitle />}>
        <InputGuesser source={"contentUrl"} />
    </EditGuesser>
);

export const MediaObjectCreate = props => (
    <CreateGuesser {...props}>
        <FileInput source="file">
            <FileField source="src" title="title" />
        </FileInput>
    </CreateGuesser>
);