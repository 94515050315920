import {
    FunctionField
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    expired: {
        backgroundColor: "#FFD6D6",
        color: "red"
    },
    inForce: {
        backgroundColor: "#C4E5C6",
        color: "green"
    }
}));

type Status = "expired" | "notAvailable" | "inForce"
interface ContractStatusProps {
    record: {
        contractStatus: Status
    }
}

export const ContractStatus = ({ record }: ContractStatusProps) => {
    const classes = useStyles();
    switch (record.contractStatus) {
        case 'expired':
            return (
                <FunctionField render={() =>
                    <Chip label="Encerrado" size="small" className={classes.expired} />
                } />)
        case 'notAvailable':
            return (
                <FunctionField render={() =>
                    <Chip label={"N/D"} size="small" />
                } />)
        case 'inForce':
            return (
                <FunctionField render={() =>
                    <Chip label={"Vigente"} size="small" className={classes.inForce} />
                } />)
        default:
            return (
                <FunctionField render={record =>
                    <Chip label={record.contractStatus} size="small" />
                } />)
    }


}