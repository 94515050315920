import {
    Create, SimpleForm, TopToolbar, List, Datagrid, ShowButton,
    EditButton, TextInput, Show, SimpleShowLayout,
    TextField, Edit} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    box: {
        display: 'flex',
        justifyContent: 'space-between',
    }
});

const EntityTitle = ({ record }) => {
    return <span>Entidade</span>; //{record ? `"${record.supplier}"` : ''}</span>;
};

const EntityShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const EntityList = props => (
    <List {...props} title={<EntityTitle />}>
        <Datagrid>
            <TextField source="name" label="Entidade" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);


export const EntityShow = props => {
    const classes = useStyles();
    return (
        <Show {...props} title={<EntityTitle />} actions={<EntityShowActions />}>
            <SimpleShowLayout className={classes.box}>
                <TextField source="name" label="Entidade" />
            </SimpleShowLayout>
        </Show>
    )
};


export const EntityEdit = props => (
    <Edit {...props} title={<EntityTitle />}>
        <SimpleForm redirect="list">
            <TextInput source="name" label="Entidade" />
        </SimpleForm>
    </Edit>
);

export const EntityCreate = props => (
    <Create {...props} title={<EntityTitle />}>
        <SimpleForm redirect="list">
            <TextInput source="name" label="Entidade" />
        </SimpleForm>
    </Create>
);
