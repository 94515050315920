import { useGetMany, Loading } from 'react-admin';

export function RegisteredPayments(props): JSX.Element {
    const { record } = props;
    const { data, loading, error } = useGetMany('payments', record.payments);
    const totalPaid = data.reduce((acc, record) => (
        acc + record.totalCurrencyInForeignCurrency
    ), 0)

    switch (record.payments.length) {
        case 0:
            return (
                <>
                    <br />Não há pagamentos registrados.
                </>);
        case 1:
            return (<>
                <br />1 pagamento registrado.
            </>);
        default:
            if (loading) { return <Loading />; }
            if (error) { return <p>ERROR</p>; }
            return (
                <>
                    <br />{record.payments.length} pagamentos registrados.
                    { }
                    <br />Valor total pago: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.currency }).format(totalPaid / 100)}
                    {record.totalCurrencyInForeignCurrency - totalPaid > 0 ? ` (Faltam ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.currency }).format(record.totalCurrencyInForeignCurrency - totalPaid / 100)})` : ''}
                </>
            );
    }
}
