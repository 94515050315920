

import {
    Button
} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        padding: 5,
        margin: '10px 0px 10px 0px',
        border: '1px solid darkblue',
    },
});

export const AddNewFileButton = ({ record }) => {
    const classes = useStyles();
    return (
        <>
            <Link
                underline="none"
                component={RouterLink}
                to={{
                    pathname: "/contract-file-objects/create",
                    state: { contract: record.id }
                }}
            >
                <Button
                    label="Adicionar arquivo"
                    className={classes.button}
                >
                    <DescriptionIcon />
                </Button>
            </Link>
        </>
    )
}