import { Container } from "./styles";

interface PaymentInfoTableProps {
    record: {
        exchangeRate?: number;
        totalCurrencyInForeignCurrency: number;
        bankFees: number;
        totalEffectiveOrder: number;
        iofRate: number;
        currency: string;
    }
}

export const PaymentInfoTable = ({ record }: PaymentInfoTableProps) => {
    const expoente = (record.exchangeRate) ? (record.exchangeRate.toString().length - 1) : 1;
    const casasDecimais = Math.pow(10, expoente);
    const exchangeRate = (record.exchangeRate) ? record.exchangeRate / casasDecimais : 1;
    const formattedCurrency = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(exchangeRate)
    const totalCurrencyInForeignCurrency = Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.currency }).format(record.totalCurrencyInForeignCurrency/100);
    const bankFees = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(record.bankFees/100)
    const iofValue = (record.totalCurrencyInForeignCurrency/100) * (record.iofRate/100) * exchangeRate;
    const iof = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(iofValue/100);
    const total = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(record.totalEffectiveOrder/100)
    const totalCost = (record.totalCurrencyInForeignCurrency/100)*exchangeRate;
    const formattedTotalCost = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(totalCost)
    return (
        <Container>
            <table>
                <tbody>
                    <tr>
                        <td>Valor da fatura</td>
                        <td>{totalCurrencyInForeignCurrency}</td>
                    </tr>
                    <tr>
                        <td>Taxa de câmbio (x)</td>
                        <td>{formattedCurrency}</td>
                    </tr>
                    <tr className="total">
                        <td >Custo bruto (=)</td>
                        <td>{formattedTotalCost}</td>
                    </tr>
                    <tr>
                        <td>Taxas bancárias (+)</td>
                        <td>{bankFees}</td>
                    </tr>
                    <tr>
                        <td>Imposto - IOF ({(record.iofRate/100)}%) (+)</td>
                        <td>{iof}</td>
                    </tr>
                    <tr>
                        <td className="total">Valor total pago (=)</td>
                        <td>{total}</td>
                    </tr>
                </tbody>
            </table>
        </Container>
    );
}