import {
    Datagrid,
    ShowButton,
    EditButton,
    TextField
} from 'react-admin';
import { ContractStatus } from '../../Components/Contract/ContractStatus';
import { ContractTerm } from '../../Components/Contract/ContractTerm';
import { CurrencyFormat } from '../../Components/FormFields/CurrencyFormat';

export const ContractDataGrid = props => (
    <Datagrid>
        <TextField source="customer.name" label="Cliente" />
        <TextField source="title" label="Título" sortable={false} />
        <ContractStatus label="Status" />
        <ContractTerm label="Período" />
        {props.includesColumns?.includes("originalValue") && <CurrencyFormat source={"value"} currency={"currency"} label="Valor original" addLabel={true} />}
        <CurrencyFormat source={"totalContractValue"} label="Valor global" currency={"currency"} addLabel={true} />
        <ShowButton />
        <EditButton />
    </Datagrid>
)