
import {
    Create,
    TopToolbar,
    List,
    EditButton,
    Show,
    Edit,
} from 'react-admin';
import { ContractForm } from './contracts/ContractForm';
import { ContractDataGrid } from './contracts/ContractDataGrid';
import { ContractView } from './contracts/ContractView';

const transform = data => {
    var baseData = {
        startDate: data.startDate,
        endDate: data.endDate,
        signedDate: data.signedDate,
        value: data.value,
        title: data.title,
        currency: data.currency,
        customer: data.customer["@id"],
        formEnctype: 'multipart'
    }
    if (data.contractFile) {
        baseData = {
            ...baseData,
            contractFile: data.contractFile
        }
    }
    return (baseData)
}

const transformCreate = data => ({
    ...data,
    formEnctype: 'multipart'
})

const ContractTitle = ({ record }) => {
    return <span>Contrato</span>; //{record ? `"${record.supplier}"` : ''}</span>;
};

const ContractShowActions = ({ basePath, data, record }) => {
    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
        </TopToolbar>
    )
};

export const ContractList = props => (
    <>
        <List {...props} title={<ContractTitle />} sort={{ field: 'startDate', order: 'ASC' }} >
            <ContractDataGrid />
        </List>
    </>
);

export const ContractShow = props => {
    return (
        <Show {...props} title={<ContractTitle />} actions={<ContractShowActions />}>
            <ContractView />
        </Show>
    )
};


export const ContractEdit = props => (
    <Edit {...props} title={<ContractTitle />} transform={transform}>
        <ContractForm />
    </Edit>
);

export const ContractCreate = props => (
    <Create {...props} title={<ContractTitle />} transform={transformCreate}>
        <ContractForm />
    </Create>
);


