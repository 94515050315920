import { useGetList, Loading } from 'react-admin';
import { useMemo } from 'react';
import { DashboardLayout } from './DashboardLayout';

const Dashboard = () => {
    const { data: events, ids: eventsIds, loading: isContractEventsLoading, total: contractEventsTotal } = useGetList(
        'contract-events',
        { page: 1, perPage: 50 },
        { field: 'date', order: 'ASC' }
    );
    const { data: payments, ids: paymentsIds, loading: isPaymentsLoading, total: paymentsTotal } = useGetList(
        'payments',
        { page: 1, perPage: 50 }
    );
    const { data: invoices, ids: invoicesIds, loading: isInvoicesLoading, total: invoicesTotal } = useGetList(
        'invoices',
        { page: 1, perPage: 50 }
    );
    const { data: contracts, ids: contractsIds, loading: isContractsLoading, total: contractsTotal } = useGetList(
        'contracts',
        { page: 1, perPage: 50 }
    );

    const contractEventsTotalValue = useMemo(() => contractEventsTotal, [contractEventsTotal])
    const paymentsTotalValue = useMemo(() => paymentsTotal, [paymentsTotal])
    const invoicesTotalValue = useMemo(() => invoicesTotal, [invoicesTotal])
    const contractsTotalValue = useMemo(() => contractsTotal, [contractsTotal])
    const contractsLifetimeValue = useMemo(() => {
        return eventsIds
            .map((id) => ((events[id].updateValue) ? events[id].newValue : 0))
            .reduce((a, b) => a + b, 0);
    }, [eventsIds, events]);
    const paymentsLifeTimeValue = useMemo(() => {
        return paymentsIds
            .map((id) => (payments[id].totalEffectiveOrder))
            .reduce((a, b) => a + b, 0);
    }, [paymentsIds, payments]);
    
    const numberOfInvoicesWithoutPayment = invoicesIds.map((id) => invoices[id].payments.length).filter((payments) => payments === 0).length
    const invoicesAlert = {alert: (numberOfInvoicesWithoutPayment>0), indicator: `${numberOfInvoicesWithoutPayment}`, message: `Há ${numberOfInvoicesWithoutPayment} faturas sem pagamento.`, icon: 'invoice'}
    
    const numberOfContractEventsRequiringInvoices = eventsIds.filter(id => events[id]?.updateValue).filter((id)=> events[id].invoices.length === 0).length
    const contractEventsAlert = {alert: numberOfContractEventsRequiringInvoices>0, indicator: `${numberOfContractEventsRequiringInvoices}`, message: `Há ${numberOfContractEventsRequiringInvoices} eventos contratuais que precisam associar uma fatura.`, icon: 'invoice'}
    
    
    return (
        <>
            {(isContractEventsLoading || isPaymentsLoading || isContractsLoading || isInvoicesLoading)
                ? <Loading />
                : <DashboardLayout
                    contractEventsTotalQtyValue={contractEventsTotalValue}
                    contractEvents={events}
                    contractEventsIds={eventsIds}
                    paymentsTotalQtyValue={paymentsTotalValue}
                    payments={payments}
                    paymentsIds={paymentsIds}
                    paymentsLifeTimeValue={paymentsLifeTimeValue}
                    invoicesTotalQtyValue={invoicesTotalValue}
                    invoices={invoices}
                    invoicesIds={invoicesIds}
                    contractsTotalQtyValue={contractsTotalValue}
                    contracts={contracts}
                    contractsIds={contractsIds}
                    contractsLifetimeValue={contractsLifetimeValue}
                    invoicesAlert={invoicesAlert}
                    contractEventsAlert={contractEventsAlert}
                />
            }
        </>
    );

}
export default Dashboard;