import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 2rem;
    width: 100%;

    table { 
        width:100%;
        border-spacing: 0 0.5rem;

        th { 
            color: 'red';
            font-weight: bold;
            padding: 1rem 2rem;
            text-align: left;
            line-height: 1.5rem;
        }

        td { 
            padding: 1rem 2rem;
            border: 0;
            background: #D7EFFF;
            border-radius: 0.25rem;
        }

        tr { 
            &:last-child {
                font-weight: bold;
            }

        }
        
        .total {
            font-weight: bold;
        }
    }  
`;