const customPortugueseMessages = {
    domain: {
        auth: {
            email: 'E-mail',
            forgotPasswordButton: 'Resetar senha',
            forgotPasswordMailSentMessage: 'E-mail enviado com sucesso',
            forgotPasswordChangeSuccessfull: 'A senha foi alterada com sucesso',
            code: 'Código',
            password: 'Senha',
            tokenInvalid: 'O token está inválido',
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        insights: {
            total_lawsuits: "Total de processos",
        },
        dashboard: {
            total_lawsuits: 'Total de processos',
            total_companies: 'Total de organizações',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Bem vindo ao sistema da ETTBrasil Software Reseller',
                subtitle:
                    "Aqui você poderá acessar os recursos para a revenda de softwares da ETTBrasil.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            admin: 'Admin',
            buy: 'Compras',
            sell: 'Vendas',
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        users: {
            name: 'Usuário |||| Usuários'
        },
        supplier: {
            name: 'Fornecedor |||| Fornecedores'
        },
        mediaObject: {
            name: 'Midia |||| Mídias'
        },
        invoices: {
            name: 'Fatura |||| Faturas'
        },
        customer: {
            name: 'Cliente |||| Clientes'
        },
        contracts: {
            name: 'Contrato |||| Contratos'
        },
        payments: {
            name: 'Pagamentos |||| Pagamentos'
        },
        paymentFileObjects: {
            name: 'Arquivo de Pagamento |||| Arquivos de Pagamentos'
        },
        entities: {
            name: 'Entidade |||| Entidades'
        },
        fileTypes: {
            name: 'Tipo de arquivo |||| Tipos de arquivos'
        },
        banks: {
            name: 'Banco |||| Bancos'
        },
        contractEvents: {
            name: 'Evento |||| Eventos contratuais'
        },
        contractFileObjects: {
            name: 'Arquivo de contrato |||| Arquivos de contratos'
        },
        eventTypes: {
            name: 'Tipo de evento contratual |||| Tipos de eventos'
        }
    },
};

export default customPortugueseMessages;