import {
    Create,
    TopToolbar,
    List,
    EditButton,
    Show,
    Edit
} from 'react-admin';
import { InvoiceForm } from './invoices/InvoiceForm';
import { InvoiceDataGrid } from './invoices/InvoiceDataGrid';
import { InvoiceView } from './invoices/InvoiceView';

interface baseDataType {
    issueDate: any;
    supplier: any;
    customer: any;
    totalValue: any;
    currency: any;
    formEnctype: any;
    contractEvent: any;
    invoiceFile?: any | undefined;
}

const transform = data => {
    var baseData: baseDataType = {
        issueDate: data.issueDate,
        supplier: data.supplier["@id"],
        customer: data.customer["@id"],
        totalValue: parseInt(data.totalValue),
        currency: data.currency,
        formEnctype: 'multipart',
        contractEvent: data.contractEvent,
    }
    if (data.invoiceFile) {
        baseData = {
            ...baseData,
            invoiceFile: data.invoiceFile
        }
    }
    return (baseData)
}

const transformCreate = data => ({
    ...data,
    formEnctype: 'multipart'
})

const InvoiceTitle = ({ record }) => {
    return <span>Fatura</span>;
};

const InvoiceShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);



export const InvoiceList = props => (
    <List {...props} title={<InvoiceTitle record={props.record} />}>
        <InvoiceDataGrid includesColumns={['supplierName', 'contractTitle', 'issueDate', 'payments', 'totalValue', 'viewButton', 'showButton', 'editButton']} {...props} />
    </List>
);


export const InvoiceShow = props => {
    return (
        <Show {...props} title={<InvoiceTitle record={props.record} />} actions={<InvoiceShowActions {...props} />}>
            <InvoiceView />
        </Show>
    )
};


export const InvoiceEdit = props => (
    <Edit {...props} title={<InvoiceTitle record={props.record} />} transform={transform}>
        <InvoiceForm children={props.children} />
    </Edit>
);

export const InvoiceCreate = props => (
    <Create {...props} title={<InvoiceTitle record={props.record} />} transform={transformCreate}>
        <InvoiceForm add={true} children={props.children} />
    </Create>
);
