
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';

interface DefaultAreaGraphApexChartsProps {
    title: string;
    subtitle?: string;
    categories: any[];
    series: any[];
    width?: any;
    overrideOptions?: ApexOptions;
    titleSize?: number | string;
    type: string;
}


export const DefaultAreaGraphApexCharts = ({
    title,
    subtitle,
    categories,
    series,
    width,
    overrideOptions,
    type
}: DefaultAreaGraphApexChartsProps) => {
    const theme = useTheme();
    const [options, setOptions] = useState<ApexOptions>({
        title: {
            text: title ?? '',
            align: "left" as "left",
            offsetX: 20,
            offsetY: 30,
            style: {
                fontSize: '1.5rem',
                fontFamily: theme.typography.fontFamily
            },
            floating: true,
        },
        subtitle: {
            text: subtitle ?? '',
            offsetX: 20,
            offsetY: 50,
            style: {
                fontSize: '1rem',
                fontFamily: theme.typography.fontFamily
            }
        },
        colors: ["#02426a",
            '#2f4b7c',
            '#665191',
            '#a05195',
            '#d45087',
            '#f95d6a',
            '#ff7c43',
            '#ffa600'],
        chart: {
            type: 'area',
            toolbar: {
                show: false
            },
            offsetX: 0,
            offsetY: 0,
            zoom: {
                enabled: false,
            },
            height: '100%',
            width: width ?? '100%',
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
            padding: {
                top: 0,
                right: -1,
                bottom: -13,
                left: -10
            },
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            labels: {
                show: false,
            },
            title: {
                text: undefined,
            },
            type: 'datetime' as "datetime",
            categories: categories,
        },
        yaxis: {
            title: {
                text: undefined,
            },
            labels: {
                show: false,
                minWidth: 0,
                maxWidth: 100,
                formatter: (val) => val.toString()
            },
            min: 0,
        },
        tooltip: {
            x: {
                formatter: (val) => new Date(val).getUTCFullYear().toString()
            },
            y: {

            }
        },
    });



    useEffect(() => {
        const newOptions = { ...options };
        if (type === 'value') {
            newOptions.tooltip = {
                y: {
                    formatter: val => new Intl.NumberFormat("pt-BR", { style: 'currency', currency: "BRL" }).format(val)
                }
            }
            newOptions.title = {
                text: `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(parseInt(title) / 100)}`
            }
        }
        const finalOptions = _.merge({}, newOptions, overrideOptions);
        setOptions(finalOptions);
    }, [overrideOptions, type, title, options])



    return (
        <Chart options={options} series={series} width={options.chart?.width} height={options.chart?.height} type={options.chart?.type} />
    );

}
