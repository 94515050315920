
export const CurrencyFormat = (props) => {
  let value = getValue(props)
  let currency = getCurrency(props)
  let intlFormat = getIntlFormat(currency)
  if (value === 0 || value === undefined) { return (<span>-</span>) }
  let formattedCurrency = new Intl.NumberFormat(intlFormat, { style: 'currency', currency: currency }).format(value / 100)
  return (<span> {formattedCurrency}</span>)
}


const getValue = ({ record, source }) => {
  return source ? record?.[source] : 0;
}

const getCurrency = ({ record, currency }) => {
  return currency ? record.currency ?? currency : "BRL";
}

const getIntlFormat = (currency) => {
  return (currency === "USD") ? "en-US" : (currency === "EUR") ? "fr" : "pt-BR";
}