import { FunctionField, Record } from 'react-admin';
import { PublicFieldProps, InjectedFieldProps } from 'react-admin';

export const InvoiceField = <RecordType extends Record = Record>({ record }: InvoiceFieldProps<RecordType>) => {
    const data = new Date(record?.issueDate)
    return (<FunctionField record={record} render={record => `Fatura emitida em ${(data.getUTCDate() < 10) ? 0 : ""}${data.getUTCDate().toString()}/${((data.getUTCMonth()+1) < 10) ? 0 : ""}${data.getUTCMonth() + 1}/${data.getUTCFullYear().toString()} por ${record.supplier?.name}`} />);
};

interface InvoiceFieldProps<RecordType extends Record = Record>
    extends PublicFieldProps,
    InjectedFieldProps<RecordType> {
}