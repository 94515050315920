import {
    FunctionField
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { CurrencyFormat } from '../FormFields/CurrencyFormat';

const useStyles = makeStyles(() => ({
    display: {
        display: 'flex',
        alignItems: 'center',
    },
    invoices: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#fff',
    },
    field: {
        display: 'flex',
    },
    green: {
        backgroundColor: 'green',
    },
    red: {
        backgroundColor: 'red',
    }
}));

interface PriceValuesProps {
    record: {
        newValue: string;
        updateValue: boolean;
    },
    label: string;
    currency: string;
}

export const PriceValues = ({ record, label, currency }: PriceValuesProps) => {
    const classes = useStyles();
    return (
        <FunctionField className={classes.field} label={label} render={() => {
           return record?.updateValue ?
            <span className={classes.display}>
                   <DoneIcon /><CurrencyFormat source="newValue" label="Novo valor" currency={currency} record={record} sortable={false} />
            </span> :
            <span>
                <ClearIcon />
            </span>
        }
        } />
    )
}