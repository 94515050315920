import {
    SimpleForm,
    DateInput,
    FileInput,
    FileField,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    required,
    number
} from 'react-admin';
import CurrencyInput from '../../Components/FormFields/CurrencyInput';
import { dateFormatter } from '../../Components/FormFields/DateFormater';


export const ContractForm = (props) => (
    <SimpleForm redirect="list" {...props}>
        <TextInput source={"title"} label={"Título"} validate={required()}/>
        <CustomerField />
        <DateInput format={dateFormatter} source={"startDate"} label="Data da início da vigência" initialValue={new Date().toISOString().slice(0, 10)} />
        <DateInput format={dateFormatter} source={"endDate"} label="Data da fim da vigência" initialValue={new Date().toISOString().slice(0, 10)} />
        <DateInput format={dateFormatter} source={"signedDate"} label="Data da assinatura do contrato" initialValue={new Date().toISOString().slice(0, 10)} />
        <NumberInput source={"value"} label="Valor do contrato" step={1} validate={[required(), number()]} />
        <CurrencyInput />
        <FileInput source="contractFile">
            <FileField source="src" title="title" />
        </FileInput>
    </SimpleForm>
)

const CustomerField = ({ record }) => {
    const field = record?.customer?.["@id"] ? 'customer.@id' : 'customer'
    return (<ReferenceInput source={field} reference="customers" validate={required()}>
        <SelectInput optionText="name" />
    </ReferenceInput>);
}
