import {
    FileInput,
    SimpleForm,
    FileField,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useRecordContext
} from 'react-admin';
import { useLocation } from "react-router-dom";



export const PaymentObjectForm = (props) => {
    const record = useRecordContext();
    const location = useLocation();
    const redirect = getRedirect(location);
    const initialValue = location?.state?.payment ?? null;

    return (
        <SimpleForm redirect={redirect ? redirect : 'list'} save={props.save} record={record}>
            <ReferenceInput
                source={"payment.@id"}
                reference="payments"
                validate={[required()]}
                disabled={props.operation === "edit" || initialValue ? true : false} >
                <SelectInput optionValue={'@id'} optionText={'@id'} initialValue={initialValue} />
            </ReferenceInput>
            <TextInput source="description" title="Descrição" validate={[required()]} />
            <ReferenceInput source={"fileType"} reference="file-types" validate={[required()]}
                filterToQuery={searchText => ({ "entity.name": "Pagamentos" })}>
                <SelectInput optionValue="id" optionText="description" />
            </ReferenceInput>
            <FileInput source="file">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
}

const getRedirect = (location) => {
    const payment = location.state?.payment ? location.state?.payment : null;
    const redirect = payment ? `/payments/${encodeURIComponent(payment)}/show/files` : "list";
    return redirect;
}