
import polyglotI18nProvider from 'ra-i18n-polyglot';

import ptBrMessages from 'ra-language-pt-br';
// domain translations
import customPortugueseMessages from '../i18n/pt-br.js';

const messages = {
    'pt-br': {...customPortugueseMessages,...ptBrMessages} ,
  };
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'pt-br');
export default i18nProvider;