import {
    Button
} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        padding: 5,
        margin: '10px 0px 10px 0px',
        border: '1px solid darkblue',
    },
});

interface AddNewPaymentButtonProps {
    record: {
        id: number;
    }
}

export const AddNewPaymentButton = ({ record }: AddNewPaymentButtonProps) => {
    const classes = useStyles();
    return (<>
        <Link
            underline="none"
            component={RouterLink}
            to={{
                pathname: "/payments/create",
                state: { invoice: record.id }
            }}
        >
            <Button
                label="Adicionar pagamento"
                className={classes.button}
            >
                <DescriptionIcon />
            </Button>
        </Link>
    </>)
}
