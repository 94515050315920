import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { FilterList, FilterListItem } from 'react-admin';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { useGetList, Loading } from 'react-admin';

const PaymentFilter = ({ invoices, ids }) => {
    return (
        <FilterList
            label="Fatura"
            icon={<LocalOfferIcon />}
        >
            {ids.map(
                id => {
                    const invoiceDate = new Date(invoices[id]?.issueDate);
                    return (
                        <FilterListItem
                            label={`${(invoiceDate.getUTCDate() < 10) ? 0 : ""}${invoiceDate.getUTCDate().toString()}/${((invoiceDate.getUTCMonth() + 1) < 10) ? 0 : ""}${invoiceDate.getUTCMonth() + 1}/${invoiceDate.getUTCFullYear().toString()} (${invoices[id]?.supplier.name})`}
                            value={{ invoice: id }}
                        />
                    )
                }
            )}
        </FilterList>
    )
};

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

export const PaymentFilterSidebar = () => {
    const { data, ids, loading } = useGetList(
        'invoices',
        { page: 1, perPage: 50 },
        { field: 'issueDate', order: 'ASC' });
    return (
        <Card>
            <CardContent>
                {loading ? <Loading /> : <PaymentFilter invoices={data} ids={ids} />}
            </CardContent>
        </Card>
    )
};