import {
    Create,
    List,
    Show,
    Edit,
} from 'react-admin';
import { PaymentForm } from './payments/PaymentForm';
import { PaymentDatagrid } from './payments/PaymentDatagrid';
import { PaymentView } from './payments/PaymentView';
import { PaymentFilterSidebar } from './payments/PaymentFilterSidebar';


const PaymentTitle = () => {
    return <span>Contrato</span>;
};

export const PaymentList = props => (
    <List {...props} title={<PaymentTitle />} aside={<PaymentFilterSidebar />}>
        <PaymentDatagrid />
    </List>
);

export const PaymentShow = props => (
    <Show {...props} title={<PaymentTitle />}>
        <PaymentView {...props} />
    </Show>
);
export const PaymentEdit = props => (
    <Edit {...props} title={<PaymentTitle />} >
        <PaymentForm />
    </Edit>
);
export const PaymentCreate = props => (
    <Create {...props} title={<PaymentTitle />} >
        <PaymentForm />
    </Create>
);