import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    useTranslate,
    usePermissions,
    DashboardMenuItem,
    MenuItemLink,
} from 'react-admin';
import { useStore } from 'react-redux'
import LayoutSubMenu from './LayoutSubMenu.js';
//icons
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonIcon from '@material-ui/icons/Person';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import BusinessIcon from '@material-ui/icons/Business';
import ListIcon from '@material-ui/icons/List';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { BsFiles, BsCalendarEvent } from 'react-icons/bs';
import { RiAdminFill } from "react-icons/ri";
import { FaFileInvoice, FaFileSignature } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { ImFilesEmpty } from "react-icons/im";


const useStyles = makeStyles({
    open: {
        transition: 'width 250ms',
        width: 200,
    },
    closed: {
        transition: 'width 250ms',
        width: 55,
    },
})

const LayoutMenu = ({ onMenuClick, logout, dense = false }) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const store = useStore();
    const [state, setState] = useState({
        menuBuy: true,
        menuAdmin: true,
        menuSell: true,
    });
    const translate = useTranslate();
    const open = store.getState().admin.ui.sidebarOpen;
    useSelector((AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div
            className={(open) ? classes.open : classes.closed}
        >
            <Box mt={1}>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
                {permissions === 'ROLE_ADMIN' ?
                    <>
                        <LayoutSubMenu
                            handleToggle={() => handleToggle('menuAdmin')}
                            isOpen={state.menuAdmin}
                            sidebarIsOpen={open}
                            name="pos.menu.admin"
                            icon={<RiAdminFill size="1.5rem" />}
                            dense={dense}
                        >
                            <MenuItemLink
                                to={`/users`}
                                primaryText={translate(`resources.users.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<PersonIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/media-objects`}
                                primaryText={translate(`resources.mediaObject.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<PermMediaIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/entities`}
                                primaryText={translate(`resources.entities.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<ListIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/file-types`}
                                primaryText={translate(`resources.fileTypes.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<BsFiles size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/contract-event-types`}
                                primaryText={translate(`resources.eventTypes.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<BsCalendarEvent size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/banks`}
                                primaryText={translate(`resources.banks.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<AccountBalanceIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                        </LayoutSubMenu>
                        <LayoutSubMenu
                            handleToggle={() => handleToggle('menuBuy')}
                            isOpen={state.menuBuy}
                            sidebarIsOpen={open}
                            name="pos.menu.buy"
                            icon={<GiPayMoney size="1.5rem" />}
                            dense={dense}
                        >
                            <MenuItemLink
                                to={`/suppliers`}
                                primaryText={translate(`resources.supplier.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<BusinessCenterIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />

                            <MenuItemLink
                                to={`/invoices`}
                                primaryText={translate(`resources.invoices.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<FaFileInvoice size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/payments`}
                                primaryText={translate(`resources.payments.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<MdOutlinePayments size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/payment-file-objects`}
                                primaryText={translate(`resources.paymentFileObjects.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<ImFilesEmpty size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                        </LayoutSubMenu>
                        <LayoutSubMenu
                            handleToggle={() => handleToggle('menuSell')}
                            isOpen={state.menuSell}
                            sidebarIsOpen={open}
                            name="pos.menu.sell"
                            icon={<GiReceiveMoney size="1.5rem" />}
                            dense={dense}>
                            <MenuItemLink
                                to={`/customers`}
                                primaryText={translate(`resources.customer.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<BusinessIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/contracts`}
                                primaryText={translate(`resources.contracts.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<FaFileSignature size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/contract-events`}
                                primaryText={translate(`resources.contractEvents.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<BsCalendarEvent size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                            <MenuItemLink
                                to={`/contract-file-objects`}
                                primaryText={translate(`resources.contractFileObjects.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<ImFilesEmpty size="1.5rem" />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />
                        </LayoutSubMenu> </> : null}

            </Box>
        </div>
    );
};

export default LayoutMenu;