import { Loading } from 'react-admin';
import { useState, useEffect } from "react";
import { DefaultAreaGraphApexCharts } from './DefaultAreaGraphApexCharts';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Badge from '@material-ui/core/Badge';
import CardContent from '@material-ui/core/CardContent';
import { ApexOptions } from 'apexcharts';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles({
    root: {
        margin: '5px',
        padding: '0',
    },
    content: {
        height: '100px',
        overflow: 'hidden',
        margin: '0',
        padding: '0',
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    alert: {
        position: 'absolute',
        display: 'inline-block',
        width: '100%',
        textAlign: 'right',
        marginTop: '1rem'
    },
    alertItem: {
        marginRight: '1.5rem',
        color: '#f44336',
        cursor: 'hand'
    }
});

interface TotalWithYearGraphsProps {
    type?: 'qty' | 'value';
    years: number[];
    value: number | undefined | string;
    entity: any[];
    ids: any[];
    subtitle: string;
    dateField: string;
    priceField: string;
    heightValue?: number | string;
    titleSize?: number | string;
    alert?: Alert;
}

type Alert = {
    indicator: string;
    message: string;
    alert: boolean;
    icon: string;
}


export const TotalWithYearGraphs = ({
    type,
    years,
    value,
    entity,
    ids,
    subtitle,
    dateField,
    priceField,
    heightValue,
    titleSize,
    alert
}: TotalWithYearGraphsProps) => {
    const [categories] = useState<any[]>(years);
    const [title] = useState<number | string | undefined>(value)
    const [series, setSeries] = useState<any[]>([]);
    const classes = useStyles();
    const [options] = useState<ApexOptions>({});

    useEffect(() => {
        const values = ids.reduce((acc, id) => {
            let yearToAdd = new Date(entity[id][dateField]).getUTCFullYear();
            let valueToAdd = (type === 'value') ? parseFloat(entity[id][priceField]) / 100 : 1;
            let object = acc.find(event => event.year === yearToAdd);
            (object) ? object.value += valueToAdd : acc.push({ year: yearToAdd, value: valueToAdd });
            return [...acc];
        }, categories.map(year => ({ year, value: 0 })))
            .sort((a, b) => { return +(a.year > b.year) || +(a.year === b.year) - 1; });
        const seriesValue = values.map(item => { return { x: item.year, y: item.value } });
        const seriesName = (type === 'qty' ? 'Quantidade' : 'Valor');
        setSeries([{ name: seriesName, data: seriesValue }]);
    }, [entity, ids, categories, dateField, type, priceField]);

    var icons = {
        'invoice':  <FaFileInvoiceDollar />
    }

    if (!value) { return <Loading />; }
    return (
        <Card variant="outlined" className={classes.root}>
            <CardContent className={classes.content} style={{ height: (heightValue) ? heightValue : '' }}>
                {alert?.alert ?
                    <div className={classes.alert} >
                        <Tooltip title={alert.message} placement="top-end">
                            <Badge badgeContent={alert.indicator} className={classes.alertItem} color="error">
                                {icons[alert.icon]}                             
                            </Badge>
                        </Tooltip>
                    </div> :
                    <></>}
                <DefaultAreaGraphApexCharts
                    series={series}
                    categories={categories}
                    title={`${title}`}
                    subtitle={subtitle}
                    overrideOptions={options}
                    type={type ?? 'qty'}
                />
            </CardContent>
        </Card>
    );
};

TotalWithYearGraphs.defaultProps = {
    type: 'qty',
    priceField: 'newValue',
    height: 100
}