import {
    Create,
    ReferenceField,
    SimpleShowLayout,
    List,
    Show,
    BooleanField,
    DateField,
    TextField,
    Labeled,
    Edit
} from 'react-admin';
import { CurrencyFormat } from '../Components/FormFields/CurrencyFormat';
import { ContractEventDataGrid } from './contractEvents/ContractEventDataGrid';
import { ContractEventForm } from './contractEvents/ContractEventForm';

const ContractEventTitle = ({ record }) => {
    return <span>Eventos de contratos {record ? `"${record.id}"` : ''}</span>;
};

const ConditionalUpdatedDate = ({ record }) => {
    return record && record.updateDate
        ? (<>
            <Labeled label="Início da vigência">
                <DateField source="newStartDate" options={{ timeZone: 'Factory' }} />
            </Labeled>
            <Labeled label="Término da vigência">
                <DateField source="newEndDate" options={{ timeZone: 'Factory' }} />
            </Labeled>
        </>)
        : null;
}

const ConditionalUpdatedValue = ({ record }) => {
    return record && record.updateValue
        ? (
            <Labeled label="Valor do contrato">
                <CurrencyFormat record={record} source="newValue" />
            </Labeled>
        )
        : null;
}

export const ContractEventList = props => (
    <List {...props} title={<ContractEventTitle />}>
        <ContractEventDataGrid includesColumns={['date', 'contract', 'eventType','invoices','terms','price', 'showButton', 'editButton']} />
    </List>
);

export const ContractEventShow = props => {
    return (
        <Show title={<ContractEventTitle />} {...props}>
            <SimpleShowLayout>
                <DateField source="date" label="Data" options={{ timeZone: 'Factory' }} />
                <ReferenceField source="contract" reference={"contracts"} link={false} label="Contrato" >
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField source="type" reference={"contract-event-types"} link={false} label="Tipo de evento">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source={"updateDate"} />
                <ConditionalUpdatedDate />
                <BooleanField source={"updateValue"} />
                <ConditionalUpdatedValue />
            </SimpleShowLayout>
        </Show>
    )
};

export const ContractEventEdit = props => (
    <Edit {...props} title={<ContractEventTitle />} >
        <ContractEventForm />
    </Edit>
);

export const ContractEventCreate = props => (
    <Create {...props} title={"Criar novo evento de contrato"}>
        <ContractEventForm />
    </Create >
);
