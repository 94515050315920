
import {
    ReferenceManyField,
    SimpleShowLayout
} from 'react-admin';
import { ContractObjectDataGrid } from '../contractObjects/ContractObjectDataGrid';
import { ContractEventDataGrid } from '../contractEvents/ContractEventDataGrid';
import { AddNewFileButton } from '../../Components/FormFields/AddNewFileButton';
import { AddNewContractEventButton } from '../../Components/FormFields/AddNewContractEventButton';
import { ContractOriginalInfoView } from './ContractOriginalInfoView';
// import { ContractInvoices } from './ContractInvoices';
import { ContractInvoicesWithDatagrid } from './ContractInvoicesWithDatagrid';

export const ContractView = props => (
    <SimpleShowLayout  {...props}>
        <h1>Resumo </h1>
        <ContractOriginalInfoView {...props} />
        <h2>Eventos </h2>
        <ReferenceManyField reference="contract-events" target="contract" addLabel={false} sort={{ field: 'date', order: 'ASC' }}>
            <ContractEventDataGrid currency={props.record.currency} includesColumns={['terms','price']} />
        </ReferenceManyField>
        <AddNewContractEventButton record={props.record} />
        <h2>Arquivos </h2>
        <ReferenceManyField reference="contract-file-objects" target="contract" addLabel={false} sort={{ field: 'fileType.description', order: 'ASC' }}>
            <ContractObjectDataGrid />
        </ReferenceManyField>
        <AddNewFileButton record={props.record} />
        <h2>Faturas de compra</h2>
        <ContractInvoicesWithDatagrid record={props.record} />
    </SimpleShowLayout>
)