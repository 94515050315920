import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, fetchStart, fetchEnd, Button, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Modal, Backdrop } from '@material-ui/core';
import SinglePage from './Pdf/SinglePage';

const entrypoint = `${process.env.REACT_APP_API_BASEURL}`;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    Accept: `application/json`
} : {};

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll'
    },
    button: {
        padding: '4px 5px 4px 5px',
        margin: '10px 0px 10px 0px',
    },
});

const ViewButton = () => {
    const record = useRecordContext();
    const classes = useStyles();
    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState("")

    const handleClose = () => {
        setOpen(false);
        setContent(null);
    };

    const handleClick = () => {
        setLoading(true);
        setOpen(true)
        dispatch(fetchStart()); // start the global loading indicator 
        fetch(`${entrypoint}${record.id}/download`, { method: 'GET', mode: 'cors', headers: getHeaders() })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data.fileAsBase64;
            })
            .then((invoice) => {
                setContent(invoice)
            })
            .catch((e) => {
                notify('Error: File not downloaded', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    if (record?.hasAttachedFile === false) {
        return <></>;
    } else {
        return (
            <>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={handleClose}
                    className={classes.modal}
                    closeAfterTransition={true}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    transition={false}
                >
                    <SinglePage pdf={content} />
                </Modal>
                <Button label="Visualizar" className={classes.button} onClick={handleClick} disabled={loading}><PageviewIcon /></Button>
            </>
        )
    }
};

export default ViewButton;