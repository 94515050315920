// LoginPage.js
import React, { useState } from "react";
import { Login } from "react-admin";
import LoginForm from "./LoginForm.tsx";
import LoginForgotPasswordForm from "./LoginForgotPasswordForm";
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { CardActions, Link } from '@material-ui/core';

const styles = ({
  avatar: {
    display: 'none'
  },
});


const CustomLoginPage = props => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showForgotPasswordForm, setForgotPasswordForm] = useState(false);
  const [height, setHeight] = useState('360px')
  const handleAction = () => {
    if (showLoginForm) {
      setHeight('260px')
      setShowLoginForm(!showLoginForm);
      setTimeout(() => { setForgotPasswordForm(!showForgotPasswordForm); }, 152);
    }
    if (showForgotPasswordForm) {
      setHeight('360px')
      setForgotPasswordForm(!showForgotPasswordForm);
      setTimeout(() => { setShowLoginForm(!showLoginForm); }, 352);
    }
  };

  return (
    <Login {...props} backgroundImage="https://source.unsplash.com/daily/?background,cool">
      <div style={{ height: height, transition: 'height 550ms' }}>
        <div style={{ fontFamily: "monospace", marginTop: '15px', marginBottom: '15px', textAlign: 'center' }}>
          <img alt="Logo" src="logosite.png" />
        </div>
        <Slide direction="left" in={showForgotPasswordForm} timeout={500} mountOnEnter unmountOnExit>
          <div><LoginForgotPasswordForm /></div>
        </Slide>
        <Slide direction="right" in={showLoginForm} timeout={500} mountOnEnter unmountOnExit>
          <div><LoginForm /></div>
        </Slide>
        
        <CardActions style={{ alignItems: 'center' }}>
          <Link onClick={handleAction} style={{cursor: 'pointer'}}><p>{showLoginForm && 'Esqueci a senha'}{showForgotPasswordForm && 'Login'}</p></Link>
        </CardActions>
      </div>
    </Login>
  );
}

export default withStyles(styles)(CustomLoginPage);